<template>
  <div class="reception">
    <div class="reception_wrap">
      <div class="yh_wrap common_inner">
        <div class="yh_wrap_left" v-show="from == null || from == 'unifiedVideo'">
          <div class="yh_left_h">
            <up-loads @up-success="upfrontUrl" :upStyle="authStyle" :upTitle="authUptit"></up-loads>
            <img :src="profileObj.avatar ? profileObj.avatar : defaultPotot" alt="">
            <span>{{profileObj.realName}}</span>
            <p>{{profileObj.remark}}</p>
          </div>
          <div class="yh_left_c">
            <!-- <template v-for="item in collection">
              <div class="yh_left_c_tit"  :key="item.id + 'index'">
                <span>{{item.num}}</span>
                <p>{{item.name}}</p>
            </div>
            </template> -->
          </div>
          <div class="yh_left_menu">
            <ul class="yh_menu_ul">
              <li class="yh_menu_li" v-for="(item ,ind) in menu" :key="item.id" :class="{ 'active': ind == current}" @click="moveMenu(item,ind)">
                <img :src="current == ind ? item.brightIcon : item.icon" alt="">
                <span>{{item.name}}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="yh_wrap_right">
          <!-- right -->
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/apis/studentsEndAPI/personalCenterAPI'
import upLoads from '@/components/backStageComponent/upLoads'
import { EventBus } from '@/assets/js/eventBus'
export default {
  name: 'personalCenter',
  props: ['from'],
  components: {
    upLoads
  },
  data() {
    return {
      current: 0,
      profileObj: {},
      defaultPotot: require('@/assets/img/studentImg/personalCenter/default-head.png'),
      authStyle: 'btn',
      authUptit: '',
      // collection: [
      //   { id: 1, name: '学习时长', num: '0.4h'},
      //   { id: 2, name: '考试', num: '55'},
      //   { id: 3, name: '收藏', num: '10'},
      // ],
      menu: [
        {
          id: 1 ,
          name: '收藏' ,
          icon: require('@/assets/img/studentImg/personalCenter/course-icon.png'),
          brightIcon: require('@/assets/img/studentImg/personalCenter/brightcourse.png'),
          routePath: 'collection'
        },
        {
          id: 2 ,
          name: '考试',
          icon: require('@/assets/img/studentImg/personalCenter/playBack-icon.png'),
          brightIcon: require('@/assets/img/studentImg/personalCenter/brightplayback.png'),
          routePath: 'examination'
        },
        {
          id: 3 ,
          name: '评价',
          icon: require('@/assets/img/studentImg/personalCenter/recordlist-icon.png'),
          brightIcon: require('@/assets/img/studentImg/personalCenter/brightrecord.png'),
          routePath: 'evaluate'
        },
        {
          id: 4 ,
          name: '账户',
          icon: require('@/assets/img/studentImg/personalCenter/shape-icon.png'),
          brightIcon: require('@/assets/img/studentImg/personalCenter/brightshape.png'),
          routePath: 'account'
        },
      ]
    }
  },
  mounted() {
    this.$router.push('/collection')
    this.getProfile()
  },
  methods: {
    moveMenu(item,ind) {
      this.current = ind
      if(item && item.routePath) {
        this.$router.push({name: item.routePath})
      }
    },
    async getProfile() {
      let data = await API.getProfile()
      this.profileObj = data
      if(data) {
        sessionStorage.setItem('profile',JSON.stringify(data))
      }
    },
    async upfrontUrl(url) {
      if(url) {
        let formData = {
          avatar: url
        }
        let data = await API.putProfile(formData)
        if(data && !data.status) {
          this.$store.commit('USER_INFO', formData)
          EventBus.$emit('url',url)
        }
        this.profileObj.avatar = url
      }
    },

  }
}
</script>

<style lang="scss" scoped>
::v-deep {
	.upLoad {
		position: absolute;
		top: 24px;
		left: 40px;
		background: none !important;
	}
	.upLoad_module_btn .upLoad_btn {
		z-index: 10;
		width: 104px !important;
		height: 104px !important;
	}
	.upLoad_module_btn .upLoad_btn .upLoad_originalbtn {
		border: none;
		background: none !important;
		opacity: 0;
	}
	// .el-button--primary{
	//   background: none;
	// }
}
$color: var(--colorBlue);
@mixin center {
	display: flex;
	align-items: center;
	justify-content: center;
}
@mixin vertical {
	display: flex;
	align-items: center;
	flex-direction: column;
}
.reception {
	min-height: calc(100vh - 288px);
	width: 100%;
	background: #e8f1ff;
	.reception_wrap {
		padding: 22px 0 10px 0;
		width: 100%;
		background: rgba(232, 241, 255, 1);
		.yh_wrap {
			display: flex;
			.yh_wrap_left {
				margin-right: 10px;
				padding: 10px;
				min-height: 100%;
				width: 226px;
				border-radius: 8px;
				background: #fff;
				.yh_left_h {
					position: relative;
					padding: 24px;

					@include vertical;
					img {
						z-index: 9;
						margin-bottom: 18px;
						width: 100px;
						height: 100px;
						//border: 2px solid $color;
						border-radius: 50%;
					}
					span {
						margin-bottom: 12px;
						color: $color;
						font-weight: 600;
						font-size: 24px;
						line-height: 33px;
					}
					p {
						width: 100%;
						height: 20px;
						color: #91929e;
						text-align: center;
						font-size: 14px;
						line-height: 20px;
					}
				}

				.yh_left_c {
					// margin-bottom: 50px;
					@include center;
					.yh_left_c_tit {
						margin: 0 10px;

						@include vertical;
						span {
							margin-bottom: 2px;
							color: $color;
							font-weight: 600;
							font-size: 24px;
							line-height: 33px;
						}
						p {
							color: #91929e;
							font-size: 14px;
							line-height: 20px;
						}
					}
				}

				.yh_left_menu {
					.yh_menu_ul {
						padding: 5px;

						@include vertical;
						.yh_menu_li {
							padding: 12px 0 16px 17px;
							width: 175px;
							height: 45px;
							cursor: pointer;
							::v-deep .el-icon-star-off {
								font-size: 16px;
							}
							img {
								display: inline-block;
								width: 14px;
								height: 16px;
								color: $color;
								vertical-align: middle;
							}
							span {
								display: inline-block;
								margin-left: 13px;
								color: #91929e;
								vertical-align: middle;
								font-weight: 400;
								font-size: 14px;
								line-height: 20px;
							}
						}
						.active {
							width: 175px;
							height: 45px;
							border-radius: 0 30px 30px 0;
							background-color: #deebff;
							i {
								color: $color !important;
								opacity: 1 !important;
							}
							span {
								color: $color !important;
								opacity: 1 !important;
							}
						}
					}
				}
			}

			.yh_wrap_right {
				// width: 100%;
				flex: 1;
				padding: 20px 10px;
			}
		}
	}
}
</style>
